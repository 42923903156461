.template-main {
  .template-heading {
    position: relative;

    &:after {
      // content: "";
      // background: linear-gradient(
      //   266deg,
      //   rgb(26 26 26 / 74%) 61%,
      //   rgba(26, 26, 26, 0) 100%
      // );
      width: 247px;
      height: 100px;
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.template-wrapper {
  .template-Outer {
    padding: 30px;

    .template-list {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 48px;
      max-width: 1266px;
      margin: auto;

      .model-preview-container {
        position: relative;
        z-index: 0;
        width: 100%;
        padding-bottom: 100px;

        

        &:hover {

          .model-preview,
          .model-detail {
            border-color: #f2f5f8;
          }
        }

        &.selected {

          .model-preview,
          .model-detail {
            border-color: var(--green-ui);
          }
        }

        .model-preview {
          border: solid var(--mm-border-size) var(--mm-colour-surface-400);
          border-radius: 5px 5px 0px 0px;
          height: 363px !important;
          pointer-events: none !important;
          mix-blend-mode: normal;
          border-bottom: none;
          display: flex;
          align-items: center;
          padding: 16px 16px 0 16px;

          width: 100%;
          object-fit: contain;

          div {
            padding: 0;
            height: 80% !important;
            display: flex;
            justify-content: center;
            align-items: center;

            canvas {
              padding: 0;
              max-width: 100%;
            }
          }
        }

        .model-text {
          width: 100%;
          display: flex;
          justify-content: space-between;
          // align-items: center;

          .plusMinus {
            width: 19px;
            height: 15px;
            display: inline-block;
          }

          svg {
            display: inline-block;
            cursor: pointer;
          }
        }

        .model-detail {
          border-radius: 0px 0px 5px 5px;
          padding: 16px;
          transition: background-color 0.2s ease-in-out;
          border: solid var(--mm-border-size) var(--mm-colour-surface-400);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: var(--bgDarkGray);

          &.open {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // background: #070707e0;
            background: #181819;
            opacity: 70%;
            padding-top: 32px;
          }

          h5 {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }

          .select-btn {
            border: solid var(--mm-border-size) var(--green-ui);
            border-radius: 5px !important;
            background: transparent;
            font-style: normal;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: var(--green-ui);
            padding: 7px 20px;
            font-weight: 700;
            min-width: 110px;

            &:hover {
              background-color: var(--green-ui);
              color: var(--bgInputBlack);
            }
          }

          .type {
            color: var(--green-ui);
          }

          .description,
          .features {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: normal;
            letter-spacing: 0.32478px;
            color: #fff;
          }

          .features ul {
            padding-left: 16px;
          }

          // .features{padding-left: 30px;}
          // .seprate-line { width: 50px; height: 1px; background: #fff; display: block; margin: 20px 0; }
          .seprate-line {
            display: block;
            margin: 20px 0
          }

          .model-description {
            max-height: 90%;
            overflow: hidden;
            padding-right: 20px;
          }

          


        }

        &:hover:not(.un-selected),
        &.selected {
          .model-preview {
            // background-color: var(--green-ui);
          }
        }

        &.un-selected {
          opacity: 0.3;
        }
      }

      @media (min-width: 1700px) {
        max-width: 100%;
      }

    }
  }
}

@media (max-width: 1500px) {
  .template-wrapper {
    .template-Outer {
      .template-list {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media (max-width: 1300px) {
  .template-wrapper {
    .template-Outer {
      .template-list {
        max-width: 100%;
        gap: 30px;
      }
    }
  }
}

@media (max-width: 991px) {
  .template-wrapper {
    .template-Outer {
      .template-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .template-main {
    .topbar {
      .link-wrapper {
        .button-wrapper {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 590px) {
  .template-wrapper {
    .template-Outer {
      .template-list {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}