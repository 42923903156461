.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.add-project-modal {
  padding: 0;

  &.assign-user-modal {
    .custom-modal-heading {
      padding: 0 0 0 0;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }

    .modal-sub-heading {
      text-align: center;
      width: 100%;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 30px;
    }

    .close-button {
      display: none;
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      button {
      }
    }

    .modal-cancel-btn {
      padding: 20px 0 0 0;
      text-align: center;

      span {
        @include mm-body-font;
        color: var(--lightGray);
        cursor: pointer;
        -webkit-text-decoration-line: underline;
        text-decoration-line: underline;

        &:hover {
          color: var(--redText);
        }
      }
    }
  }

  &.add-new-user {
    .modal-image-container {
      .uploaded-img {
        border-color: var(--redText);
        // &:hover{
        //   background-color: transparent;
        // }
      }
    }
  }

  .custom-modal-heading {
    padding: 25px 0 0 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  .modal-image-container {
    text-align: center;
    margin-top: 16px;

    .error-message {
      display: block;
      color: var(--redText);
    }

    .image-span {
      padding: 0;
      // background: #7F8281;
      width: 48px;
      height: 48px;
      display: flex;
      text-align: center;
      border-radius: 100px;
      margin: 18px auto 2px auto;
      padding-top: 0;
      overflow: hidden;
    }

    input {
      display: none;
    }

    label {
      @include mm-body-font;
      cursor: pointer;
      text-decoration-line: underline;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .uploaded-img {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      border: solid var(--mm-border-size) var(--purplePrimary);
      background-color: transparent;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;

      &.isUploaded {
        border: 0;

        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: var(--purplePrimary);

        svg {
          color: #070707;
        }
      }

      svg {
        color: var(--purplePrimary);
      }

      img {
        border-radius: 100px;
        object-fit: cover;
      }
    }
  }

  .modal-input-comp-container {
    display: flex;
    flex-direction: column;
    padding: 36px 0 0 0;

    label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-transform: uppercase;
    }

    .modal-input-container {
      padding: 0;
      margin-bottom: 24px;
      position: relative;

      input {
        border-radius: 5px;
        border: solid var(--mm-border-size) var(--mm-colour-surface-400);
        background: var(--bgInputBlack);
        color: var(--grayD7D7);
        height: 55px;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
        width: 100%;
        padding: 0 18px;
        font-size: 13px;
        outline: none;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;

        &:hover {
          border: solid var(--mm-border-size) var(--lightGray);
        }

        &::placeholder {
          color: var(--mm-colour-surface-400);
          font-size: 13px;
          font-weight: 500;
        }

        &:focus {
          border: solid var(--mm-border-size) var(--green-ui);
        }

        &.modal-input-error {
          border-color: var(--redText);
        }
      }

      &.invalid {
        input {
          border-color: var(--redText);
        }
      }

      &.valid {
        input {
          border-color: var(--greenText);
        }
      }
    }

    .error {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    .modal-submit-btn {
      width: 100%;
      margin-top: 25px;
    }

    .btnBorderGreen {
      font-weight: 500;
    }
  }
}

.modal-cancel-btn {
  padding: 20px 0 0 0;
  text-align: center;

  span {
    font-size: 13px;
    font-family: Satoshi;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-decoration-line: underline;

    &:hover {
      color: var(--redText);
    }
  }
}

.add-project-modal {
  .modal-content {
    border: solid var(--mm-border-size) #656667;
  }
}

.form-box {
  border: solid var(--mm-border-size) black;
  border-radius: 10px;
  padding: 15px;
  flex-direction: column;

  .upload-text {
    cursor: pointer;
    font-size: 10px;
    text-decoration: underline;
    margin-top: 5px;
    color: black;
    font-weight: 700;
  }

  .form-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: black;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .form-image {
      margin-top: -1px;
      border-radius: 100px;
    }
  }

  .error-message {
    font-size: 13px;
    /* Customize the text size */
    color: red;
  }

  .form-upload {
    margin-bottom: 1rem;
    display: none;
  }

  .form-input-comp-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .form-input-title {
      font-weight: 400;
      color: black;
      font-size: 10px;
    }

    .input-container {
      position: relative;
      display: inline-block;
      //   margin-top: 10px;

      .form-input {
        width: 400px;
        height: 35px;
        background-color: #e2ffca;
        border: solid var(--mm-border-size) black;
        border-radius: 8px;
        padding: 5px;
        font-size: 16px;
      }

      .form-input-error {
        background-color: #ffe2ca;
      }

      .checkmark {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: url("../public/validation-check.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .cancel-btn {
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
      margin-top: 10px;
      font-weight: 700;
      text-align: center;
      color: black;
    }

    .submit-btn {
      height: 35px;
      background-color: #a9f868;
      border: solid var(--mm-border-size) #2b3648;
      border-radius: 8px;
      cursor: pointer;
      margin-top: 15px;
      box-shadow: 0 2px 4px rgba(55, 81, 255, 0.24);
      color: black;
    }
  }
}

.modal-main-container {
  background-color: white;

  .modal-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
    padding: 0px;
  }
}

.modal-container {
  border: solid var(--mm-border-size) black;
  border-radius: 6px;
  flex-direction: column;
  width: 300px;

  .modal-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: black;
    margin-top: 20px;
  }

  .modal-image-upload {
    margin-bottom: 1rem;
    display: none;
  }

  .modal-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    .upload-modal-text,
    .remove-text,
    .cancel-text {
      cursor: pointer;
      font-size: 10px;
      text-decoration: underline;
      margin-top: 5px;
      font-weight: 700;
    }

    .upload-modal-text {
      color: black;
      margin-top: 20px;
    }

    .remove-text {
      color: red;
      text-align: center;
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      width: 100%;
    }

    .cancel-text {
      color: black;
      margin-bottom: 10px;
    }
  }
}
