@font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Bold.woff2") format("woff2"),
      url("./fonts/Satoshi-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Medium.woff2") format("woff2"),
      url("./fonts/Satoshi-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Regular.woff2") format("woff2"),
      url("./fonts/Satoshi-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Black.woff2") format("woff2"),
      url("./fonts/Satoshi-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Medium.woff2") format("woff2"),
      url("./fonts/Satoshi-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Regular.woff2") format("woff2"),
      url("./fonts/Satoshi-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Black.woff2") format("woff2"),
      url("./fonts/Satoshi-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Bold.woff2") format("woff2"),
      url("./fonts/Satoshi-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Light.woff2") format("woff2"),
      url("./fonts/Satoshi-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Satoshi";
    src: url("./fonts/Satoshi-Light.woff2") format("woff2"),
      url("./fonts/Satoshi-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DM Sans";
    src: url("./fonts/DMSans-Medium.woff2") format("woff2"),
      url("./fonts/DMSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DM Sans";
    src: url("./fonts/DMSans-Light.woff2") format("woff2"),
      url("./fonts/DMSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DM Sans";
    src: url("./fonts/DMSans-Regular.woff2") format("woff2"),
      url("./fonts/DMSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DM Sans";
    src: url("./fonts/DMSans-SemiBold.woff2") format("woff2"),
      url("./fonts/DMSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DM Sans";
    src: url("./fonts/DMSans-Bold.woff2") format("woff2"),
      url("./fonts/DMSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Void";
    src: url("./fonts/Void-Bold.woff2") format("woff2"),
      url("./fonts/Void-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Cygnito Mono";
    src: url("./fonts/CygnitoMono.woff2") format("woff2"),
      url("./fonts/CygnitoMono.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  span,
  ul,
  li,
  a,
  input,
  button,
  textarea,
  p {
    font-family: "Satoshi";
  }
  

  @mixin mm-body-font{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    font-family: Satoshi;
  }

  @mixin mm-text-label{
    font-family: Satoshi;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: 1px;
    text-transform: capitalize;
  }