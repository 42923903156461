/* latin-ext */


body {
  background-color: var(--bgDarkGray);
  overflow: hidden;
}

img,
svg {
  max-width: 100%;
}

#main {
  &::before {
    content: "";
    border-radius: 847px;
    // background: linear-gradient(157deg, rgb(151 71 255 / 29%) 30.21%, rgb(115 255 0 / 55%) 100%);
    // filter: blur(250px);
    width: 847px;
    height: 847px;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 250px;
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  color: var(--OffWhite);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

* {
  outline: none;
}

a,
button {
  color: var(--OffWhite);
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 9999;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: var(--green-ui) transparent var(--green-ui) transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.message-container {
  text-align: center;
  padding: 5px;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: var(--redText) !important;
  max-width: 100% !important;
  display: block !important;
}

.cus-btn {
  font-size: 13px;
  font-style: normal;
  border-radius: 5px !important;
  font-weight: 700;
  border: solid var(--mm-border-size) var(--purplePrimary);
  color: var(--bgBlackLite);
  padding: 8px 16px;
  height: auto;
  text-transform: uppercase;
  line-height: 24px;
  min-width: 95px;
  min-height: 32px;
  text-align: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  background-color: transparent;
  border: solid var(--mm-border-size) var(--purplePrimary);
  color: var(--purplePrimary);
  transition: all 0.2s ease-in;

  &.ghost-button {
    padding: 8px 16px;
    border-radius: 5px !important;
    min-width: 95px;
    min-height: 55px;
  }

  &:hover,&:active {
    background-color: var(--purplePrimary);
    color: var(--bgBlackLite);
    border-color: var(--purplePrimary);
    transition: all 0.3s ease;

    background-color: var(--purplePrimary);
    color: var(--bgBlackLite);
    transition: all 0.2s ease-in;
  }
}

h5{
  font-size: 24px;
}
.buttom-small {
  padding: 7px 10px;
  min-width: 114px;
  border-radius: 5px !important;
  font-size: 13px;
  transition: all 0.2s ease-in;
}

.button-wrapper,
.modal-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  button {
    min-height: 55px;
  }
}

.ghost-button {
  background-color: transparent;
  border: solid var(--mm-border-size) var(--purplePrimary);
  color: var(--purplePrimary);
  transition: all 0.2s ease-in;
  margin: 0;
  min-height: 55px;

  &:hover {
    background-color: var(--purplePrimary);
    color: var(--bgBlackLite);
    transition: all 0.2s ease-in;
  }
}

.button-large {
  //background-color: var(--purplePrimary);
  min-width: 341px;
  font-weight: 500;
  padding: 17px 24px;

  @media (max-width: 576px) {
    min-width: auto;
  }
}

.text-danger {
  font-size: 13px;
}

.button-painted {
}

.modal-submit-btn {
  border: solid var(--mm-border-size) var(--green-ui);
  height: 55px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--OffWhite);
  border-radius: 5px;
  padding: 0 30px;
  background: transparent;
  text-align: center;

  &:hover {
    color: #000;
    background-color: var(--green-ui);
    border-radius: 5px;
  }
}

.btnBorderGreen {
  border: solid var(--mm-border-size) var(--green-ui);
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  min-width: 95px;
  min-height: 32px;
  text-transform: uppercase;
  color: var(--green-ui);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  background: transparent;
  text-align: center;
  transition: 0.3s ease-in-out;

  &:active {
    opacity: 0.5;
    color: var(--green-ui);
  }

  &:hover {
    color: var(--bgBlackLite);
    background: var(--green-ui);
    transition: 0.3s ease-in-out;
  }
}

.btn-green {
  background: var(--green-ui);
}

@keyframes tooltipBounce {
  0% {
    transform: translateX(-50%) scale(1);
  }

  50% {
    transform: translateX(-50%) scale(1.1);
  }

  100% {
    transform: translateX(-50%) scale(1);
  }
}

.border-end {
  border-right: 1px solid var(--btn-border) !important;
}

#main {
  display: flex;

  #sidebar-wrapper {
    min-height: 100vh;
    max-width: 262px;
    transition: all 0.25s linear;
    border-right: 1px solid var(--lightGray);
    padding: 0;
    position: relative;
    background: var(--bgDarkGray);

    &.dropdown-open::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      content: "";
      right: 0;
      opacity: 0.8;
      background: var(--bgBlackLite);
    }

    .toggle-btn {
      position: absolute;
      top: 24px;
      width: 24px;
      height: 12px;
      right: 15px;

      img {
        display: none;
      }

      cursor: pointer;

      &::after {
        content: "";
        width: 24px;
        height: 2px;
        background-color: var(--OffWhite);
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease-in;
      }

      &::before {
        content: "";
        width: 24px;
        height: 2px;
        background-color: var(--OffWhite);
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.2s ease-in;
      }

      &:hover {
        &::before,
        &::after {
          background-color: var(--purplePrimary);
          transition: all 0.2s ease-in;
        }
      }
    }

    .logo {
      padding: 20px 15px;
      display: inline-flex;
    }

    .user-tile {
      display: flex;
      padding: 0.5rem 15px 15px 15px;
      margin-bottom: 1rem;
      position: relative;
      width: 100%;

      .caret-icon {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 12px;

        &:hover {
          color: var(--green-ui);
        }
      }

      .show.dropdown {
        position: absolute;
        // bottom: 0;
        top: -24px;
        right: -14px;
        width: 100%;

        // &::after {
        //   content: "";
        //   width: 0;
        //   height: 0;
        //   display: inline-block;
        //   border-left: 20px solid transparent;
        //   border-right: 20px solid transparent;
        //   border-bottom: 20px solid var(--bgDarkGray);
        //   position: absolute;
        //   right: 0;
        //   top: -12px;
        //   z-index: 9999;
        // }

        .dropdown-menu {
          // background-color: var(--bgDarkGray);
          color: var(--OffWhite);
          // border: solid var(--mm-border-size) var(--OffWhite);
          padding: 10px;
          width: calc(100% - 26px);
          line-height: 21px;
          box-shadow: 0px 20px 32px 0px #1a1a1a;

          .dropdown-item {
            font-size: 16px;
            color: var(--OffWhite);
            border-radius: 5px;
            font-weight: 400;
            padding: 5px 10px;

            &:hover {
              background-color: var(--green-ui);
              color: var(--bgDarkGray);
            }
          }

          .dropdown-item:last-child {
            background-color: transparent;
            color: red;
          }

          .org-title {
            color: var(--lightPurple);
            font-family: Satoshi;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            padding: 5px 10px;
            height: auto;
          }
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
          background-color: var(--green-ui);
          color: var(--bgDarkGray);
        }
      }

      .user-detail {
        margin-left: 10px;
        line-height: 20px;
        width: calc(100% - 56px);

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: var(--OffWhite);
        }

        .user-name {
          display: block;
          font-style: normal;
          font-weight: normal;
          color: var(--OffWhite);
          font-size: 16px;
          line-height: 20px;
        }

        .user-email {
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
        }
      }
    }

    .nav {
      .nav-item {
        display: flex;
        align-items: center;
        width: 100%;

        .nav-link {
          width: 100%;
          color: #e9f3ee;
          font-size: 16px;
          font-weight: 500;
          line-height: 43px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 12px;
            width: 26px;
          }

          &.active {
            color: var(--bgDarkGray);
            background: var(--green-ui);
          }

          &:hover {
            color: var(--bgDarkGray);
            background: var(--green-ui);
          }
        }
      }
    }

    .msgSupport {
      position: absolute;
      bottom: 18px;
      left: 15px;
      color: var(--OffWhite);
      font-size: 16px;
      font-weight: 500;

      svg {
        margin-right: 8px;
      }
    }
  }

  &.sidebar-hide {
    #sidebar-wrapper {
      max-width: 59px;
      transition: all 0.25s linear;

      .logo {
        display: none;
      }

      .toggle-btn {
        position: relative;
        right: -17px;

        &::after,
        &::before {
          height: 3px;
        }
      }

      .user-tile,
      .nav-item {
        .user-detail {
          display: none;
        }

        .nav-link {
          font-size: 0;
          left: 30px;

          svg {
            margin-right: 0;
          }
        }
      }

      .msgSupport {
        font-size: 0;
        left: 20px;
      }

      .user-tile {
        margin-top: 35px;
        padding: 0.5rem 0 12px 7px;

        .caret-icon {
          display: none;
        }
      }
    }
  }

  #main-container {
    padding: 0;
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: 100vh;
  }
}

.cart-aside {
  position: fixed;
  right: 0;
  background: var(--bgBlackLite);
  top: 0;
  bottom: 0;
  padding: 20px;
  z-index: 4;
  width: 336px;
  overflow-y: auto;
  border-left: 1px solid var(--OffWhite);

  .loader-container {
    position: unset;
    height: 100%;
  }

  .div-wrapper {
    .overlap-group-4 {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 40px);

      .frame-5 {
        border-top: solid 1px #505259;

        margin-top: 20px;
        overflow-y: auto;
        padding-top: 16px;
        margin-right: 0;
        padding-right: 20px;
        height: calc(100vh - 330px);
        margin-bottom: 30px;

        .frame-7 {
          padding: 0;
          border-bottom: solid 1px #505259;
          padding-bottom: 18px;
          margin-bottom: 15px;

          .no-item-cart {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .frame-5::-webkit-scrollbar {
        width: 8px;
      }

      .frame-5::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      .frame-5::-webkit-scrollbar-thumb {
        background: var(--purplePrimary);
        border-radius: 10px;
      }

      .frame-3 {
        .your-cart {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          text-transform: uppercase;
        }

        .cancel-instance {
          position: absolute;
          right: 18px;
          top: 25px;
          cursor: pointer;
        }
      }

      .text-wrapper-2 {
        font-size: 13px;
        margin-top: 5px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }

      .frame-4 {
        margin-top: 30px;

        .text-wrapper-3 {
          background-color: var(--purplePrimary);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          text-align: center;
          margin: 0 -20px;
          padding: 4px 0;
        }
      }

      .frame-7 {
        .frame-8 {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;

          .ROBLOX-ITEM-RENDERS-wrapper {
            width: 120px;
            height: 120px;
            border-radius: 5px;
            background-color: var(--grayD7D7);

            img {
              object-fit: contain;
              height: 100%;
              width: 100%;
            }
          }

          .frame-9 {
            padding: 0;
            width: 130px;

            .frame-10 {
              padding: 0 0 0;

              .text-wrapper-4 {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }

              .text-wrapper-5 {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                color: var(--lightPurple);
              }

              .text-wrapper-6 {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
              }
            }

            .frame-11 {
              padding: 0;
              text-align: right;

              .remove-btn {
                background-color: transparent;
                border: none;
                font-size: 13px;
                font-style: normal;
                color: var(--OffWhite);
                font-weight: 500;

                &:hover {
                  color: var(--green-ui);
                }
              }
            }
          }
        }
      }

      .frame-12 {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        border-bottom: solid 1px #505259;
        padding-bottom: 10px;

        .text-wrapper-4 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .text-wrapper-7 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .frame-15 {
        padding: 0;

        margin-top: auto;

        .frame-16 {
          padding: 0;
          border-radius: 5px;
          background-color: var(--bgInputBlack);
          border: solid var(--mm-border-size) var(--mm-colour-surface-400);
          padding: 8px;

          .text-wrapper-4 {
            padding: 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .frame-17 {
            padding: 0;
            display: flex;
            color: var(--lightPurple);
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            .text-wrapper-8 {
              padding: 0;
            }

            .text-wrapper-9 {
              padding: 0;
            }
          }

          .frame-18 {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            .text-wrapper-10 {
              padding: 0;
            }

            .text-wrapper-11 {
              padding: 0;
            }
          }

          .frame-19 {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            .text-wrapper-12 {
              padding: 0;
            }

            .text-wrapper-13 {
              padding: 0;
            }
          }
        }
      }

      .button-tertiary {
        font-size: 13px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        border-radius: 3px;
        padding: 8px 0px;
        line-height: 16px;
        color: var(--bgInputBlack);
        cursor: pointer;
        text-transform: uppercase;
        background-color: var(--purplePrimary);
        margin-top: 30px;

        &.disabled {
          cursor: default;
          opacity: 0.8;
        }
      }
    }
  }

  &.noItemWrap {
    .msgNoProduct {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 8px;
      color: var(--OffWhite);
      border: solid var(--mm-border-size) var(--redText);
      border-radius: 5px;
      margin-top: 30px;
    }

    .div-wrapper {
      .overlap-group-4 {
        .frame-4 {
          .text-wrapper-3 {
            background-color: var(--redText);
          }
        }

        .frame-7 {
          .frame-8 {
            .frame-9 {
              width: 118px;
            }
          }
        }

        .frame-15 {
          .frame-16 {
            .frame-17 {
              color: #f45848;
            }
          }
        }

        .frame-5 {
          height: calc(100vh - 580px);
        }
      }
    }
  }
}

.topbar {
  padding: 0px;
  padding-top: 10px;
  top: 0;
  background: var(--bgDarkGray);
  position: sticky;
  z-index: 3;

  .link-wrapper {
    position: relative;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 24px;
    align-items: center;
    justify-content: space-between;



    .top-links {
      display: flex;
      justify-content: flex-start;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--OffWhite);

      @media (max-width: 576px) {
        //display: none;
        overflow-x: scroll;
        scrollbar-width: none;
        flex-grow: 1;
        
        &::-webkit-scrollbar{
          display: none;
        }
      }

      .top-link {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        padding-right: 10px;
        color: var(--lightGray);
        white-space: nowrap;

        /*
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20px;
        */

        &:hover {
          color: var(--green-ui);
        }

        &:after {
          padding-left: 10px;
          content: "/";
        }

      }

      .top-link.active {
        color: var(--green-ui);
      }

      :last-child{
        &:after{
          content: none;
          padding-left: 0px;
        }
      }
      

      .navigation-arrow {
        display: flex;
        column-gap: 8px;

        a {
          display: inline-flex;
          align-items: center;

          &:hover {
            color: var(--purplePrimary);
          }
        }

        .backward {
        }

        .forward {
          display: none;
          color: #c8cacc;

          &.isVisible {
            display: inline-flex;
          }
        }
      }

      .go-back-link {
        display: flex;
        align-items: center;
      }

      
    }

    .button-wrapper {
      display: flex;

      .cart-blue {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--bgDarkGray);
        display: flex;
        align-items: center;

        svg {
          float: right;
          order: 1;
          margin-left: 9px;
        }

        &:hover {
        }

        &:active {
          opacity: 0.5;
          color: var(--green-ui);
        }
      }

      .cartWhiteIcon {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--OffWhite);
        display: flex;
        align-items: center;

        svg {
          order: 1;
          float: right;
          margin-left: 9px;
        }

        &:hover {
        }

        &:active {
          opacity: 0.5;
          color: var(--green-ui);
        }
      }

      .cartDarkIcon {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--bgInputBlack);
        display: flex;
        align-items: center;

        svg {
          order: 1;
          float: right;
          margin-left: 9px;
        }

        &:hover {
        }

        &:active {
          opacity: 0.5;
          color: var(--green-ui);
        }
      }
    }
  }

  .template-heading {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    

    @media (min-width: 1300px) {
      margin-top: 12px;
    }

    h3 {
      color: var(--OffWhite);
      font-size: 48px;
      font-style: normal;
      font-weight: 500;

      text-transform: uppercase;
    }

    .cta-button-wrapper {
      .cta-button {
        background: radial-gradient(
          206.89% 173.7% at 56.82% 148.44%,
          #6161ff 11.46%,
          #dbb6ff 34.38%,
          #ffc68c 58.85%,
          #9cffb1 84.38%
        );
        border-radius: 5px;
        padding: 8px;
        color: var(--bgDarkGray);
        display: flex;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}


.action-row {
  // padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .left-actions {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .checkbox-group {
      margin-right: 0;
      cursor: pointer;
    }

    .checkName {
      cursor: pointer;

      svg {
        color: var(--lightGray);
      }

      &.disabled {
        cursor: default;

        svg {
          color: #2f2f30;
        }
      }
    }

    .seperator {
      color: #2f2f30;

      &.disabled {
        color: var(--mm-colour-surface-400);
      }
    }
  }

  .right-actions {
      display: flex;
      align-items: center;
      column-gap: 8px;

        span {
          cursor: pointer;
        }

        .active {
          svg {
            color: var(--purplePrimary);
          }
        }
  }
}

.modal-backdrop.show {
  opacity: 0.6;
  background-color: var(--bgBlackLite);
}

.modal-content {
  background-color: var(--bgBlackLite);
  border: solid var(--mm-border-size) var(--mm-colour-surface-400);

  .modal-body {
    padding: 30px;

    .close-button {
      border: none;
      background-color: transparent;
      color: var(--OffWhite);
      position: absolute;
      right: 14px;
      top: 3px;
      font-size: 29px;
    }
  }
}

.logout-modal-container {
  text-align: center;

  .custom-modal-heading {
    font-size: 40px;
  }

  .custom-modal-text {
    padding: 10px 0px 30px 0px;
  }
}

@media only screen and (max-width: 1300px) {
  .topbar {
    .link-wrapper {
      .top-links {
        .top-link {
          vertical-align: top;
          line-height: normal;
        }
      }

      .button-wrapper {
        .borderBtn {
          font-size: 14px;
        }
      }
    }

    .template-heading {
      h3 {
        font-size: 22px !important;
      }
    }
  }
}

/*media screen 1300 */

// @media only screen and (max-width: 1023px) {
//   #main.sidebar-open {
//     #sidebar-wrapper {
//       position: absolute !important;
//       left: 0;
//       top: 0;
//       bottom: 0;
//       z-index: 20;
//     }
//   }
// }

@media only screen and (max-width: 767px) {
  .topbar {
    .link-wrapper {
      .button-wrapper {
        justify-content: flex-start;
        margin-top: -11px;
        margin-bottom: 7px;

        .borderBtn {
          height: 40px;
        }
      }
    }
  }

  #main.sidebar-open {
    .topbar {
      .link-wrapper {
        padding: 0 20px;
      }
    }
  }



  /*----------------*/
}

.tooltip {
  &.show {
    opacity: 1;
    
  }
  .tooltip-inner {
    text-align: start;
    background: #b3c1ff;
    opacity: 1;
    padding: 10px 15px;
    max-width: 250px;
    
    span {
      font-family: Satoshi;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      color: var(--bgBlackLite);
      line-height: 21px;
    }
  }
}

.tooltip-arrow {
  &::before {
    border-top-color: #b3c1ff !important;
  }
}

.noPointer {
  cursor: default !important;

  &:hover {
    opacity: initial !important;
  }
}

.dropdown-toggle.no-icon::after {
  display: none !important;
}

.rainbow-btn {
  background: radial-gradient(
    206.89% 173.7% at 56.82% 148.44%,
    #6161ff 11.46%,
    #dbb6ff 34.38%,
    #ffc68c 58.85%,
    #9cffb1 84.38%
  );
  padding: 9px 18px;
  border-radius: 5px;
  position: relative;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  border: none;
  color: var(--bgDarkGray);
}

.foil-animation {
  background-size: 200% 200% !important;
  animation: foil 8s ease infinite;
}

@keyframes foil {
  0% {
    background-position: 84% 0;
  }
  50% {
    background-position: 17% 100%;
  }
  100% {
    background-position: 84% 0;
  }
}

.table-data {
  .form-input {
    background: var(--lightPurple);
    border: solid var(--mm-border-size) var(--neonGreen);
    border-radius: 5px;
    padding: 3px 5px;
  }
}

.day-ago{
  text-transform: capitalize;
}


.scrollbar-container{
  .ps__rail-y{
    background-color: unset !important;

    .ps__thumb-y{
      width: 5px !important;
    }
  }
}

.pagination-container{
  display: flex!important;
  justify-content: flex-end!important;
}

.border-bottom{ border-color: var(--gray333)!important;}


// SCROLLBARS

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--mm-colour-surface-400) var(--mm-colour-surface-400);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--mm-colour-surface-400);
  border-radius: 20px;
  border: 3px solid var(--mm-colour-surface-400);
}