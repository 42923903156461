.loginWrap {
    background-color: var(--bgBlackLite);
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
        &::after{
            content:''; 
            background: url(../public/loginBg.svg) no-repeat 0 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
        }
svg{ position: relative; z-index: 1;}
    .cus-btn {
        position: absolute;
        right: 25px;
        top: 15px; z-index: 1;
    }
}

.login-modal-wrapper{
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-modal {
    max-width: 500px;
    width: 100%;
    .loginInner {
        width: 100%;
        background: var(--bgBlackLite);
        position: relative;
        z-index: 1;

        h3 {
            padding: 0;
            color: var(--OffWhite);
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            text-transform: uppercase;
            margin-top: 30px;
        }

        h4 {
            padding: 0;
            color: var(--OffWhite);
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            text-transform: uppercase;
            margin-top: 30px;
        }

        p {
            font-size: 16px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: var(--OffWhite);
            margin-top: 20px;
        }

        .controlWrap {
            padding: 0;

            .control {
                margin-top: 24px;
                &.invalid{
                    input{
                        border-color: var(--redText);
                        &:focus{
                            border-color: var(--redText);
                        }
                    }
                }

                label {
                    color: #F2F5F8;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }

                input {
                    border-radius: 5px;
                    border: solid var(--mm-border-size) #7F8281;
                    background: var(--bgInputBlack);
                    color: var(--grayD7D7);
                    height: 55px;
                    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
                    width: 100%;
                    padding: 0 18px;
                    font-size: 13px;
                    outline: none;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                    &::placeholder{
                        color: var(--mm-colour-surface-400);
                        font-size: 13px;
                        font-weight: 500;
                    }
                }

                input:focus {
                    border: solid var(--mm-border-size) var(--green-ui);
                }

                button {
                    width: 100%;
                }

                .forgot-pass-label{
                    display: block;
                    font-size: 13px;
                    line-height: 21px;
                    font-weight: 400;
                    color: var(--OffWhite);
                    text-align: center;
                    margin-top: 18px;
                    text-decoration: underline;
                    text-transform: capitalize;
                }

                a {
                    cursor : pointer;
                }

            }
        }
    }
}

@media (max-width:767px) {
    .login-modal {
        .loginInner {
            h3 {
                margin-top: 0;
                font-size: 30px;
            }

            .controlWrap {
                .control {
                    input {
                        height: 40px;
                    }

                    button {
                        height: 40px;
                    }
                }
            }

            p {
                margin-top: 0;
            }
        }
    }



}