.token-list-container {
  height: 100vh;
  padding: 20px;
  overflow: auto;
  position: relative;

  .list-header {
    padding-left: 24px;
    max-width: 100%;

    .list-description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
      padding: 54px 0 8px 0;
    }
  }

  .template-heading-secondary {
    padding: 0 24px;
    margin-top: 36px;
    margin-bottom: 24px;

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
    }
  }

  .token-list-table {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border-top: 1px solid var(--mm-colour-surface-400);

    .table-head {
      border-bottom: 1px solid var(--mm-colour-surface-400);
      background: var(--bgDarkGray);

      .table-row {
        display: grid;
        grid-template-columns: 0.6fr 0.2fr 0.2fr;
        
        padding-left: 12px;
        padding-right: 0px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        min-height: 57px;

        &:not(.no-border):not(:last-child) {
          border-bottom: 1px solid var(--mm-colour-surface-400);
        }

        .table-heading {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .table-body {
      .table-row {
        display: grid;
        grid-template-columns: 0.6fr 0.2fr 0.2fr;
        padding-left: 12px;
        padding-right: 0px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        min-height: 60px;
        font-size: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--mm-colour-surface-400);
        }

        &:hover { background:var(--mm-colour-surface-400);
          border-bottom: 1px solid var(--mm-colour-surface-400);
        }


        .table-data {
          display: flex;
          align-items: center;
          color: var(--OffWhite);

          .active {
            color: var(--green);
          }


          .text-green {
            color: var(--green);
          }

          .text-red {
            color: var(--redText);
          }

          .inactive {
            color: #f75848;
          }

          .expiring {
            color: #ffc37b;
          }

          .expired {
            color: red;
          }
        }
      }
    }
  }
}