@font-face {
  font-family: "Cygnito Mono";
  src: url("./fonts/CygnitoMono.woff2") format("woff2"),
    url("./fonts/CygnitoMono.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.template-design-main,
.template-main {
  &:before {
    display: none;
  }
}

.template-design-main {
  .customize-wrapper {
    padding: 0;
  }

  .topbar {
    background: transparent;
    z-index: 2;

    .template-heading {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;

      h3 {
        position: relative;
        color: var(--bgInputBlack);
        max-width: 33%;
      }
    }
  }

  .template-design-container {
    background-color: var(--mm-editor-background);
    height: auto !important;
  }

  .topbar {
    .link-wrapper {
      .top-links {
        color: var(--mm-colour-surface-400);

        .navigation-arrow {
          a {
            color: var(--mm-colour-surface-400);

            &:hover {
              color: var(--purplePrimary);
            }
          }
        }

        .go-back-link {
          color: var(--mm-colour-surface-400);

          a {
            color: var(--mm-colour-surface-400);
          }
        }
      }

      .button-wrapper {
        .borderBtn {
          height: 32px;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
        }
      }
    }
  }

  .actionBtn {
    margin-top: auto;
    margin-left: -18px;
    margin-right: -16px;
    padding: 18px 15px 0 15px;
    border-top: 1px solid var(--mm-colour-surface-400);

    .btnBorderGreen {
      background-color: var(--purplePrimary);
      border: none;
      height: 42px;
      width: 100%;
      border-radius: 5px !important;
      color: var(--bgBlackLite);
      margin-bottom: 20px;
      padding: 10px 30px;
      text-transform: uppercase;
    }
  }

  .nouiFlex {
    height: 100vh;
    position: relative;

    .modeloader {
      height: 100%;

      canvas {
        height: 100% !important;
        max-width: 100%;
      }
    }
  }

  .statusFlex {
    height: 100vh;
    position: relative;

    .modeloader {
      height: 100%;

      canvas {
        height: 100% !important;
        max-width: 100%;
      }
    }

    .buttonBar {
      position: absolute;
      bottom: 100px;
      right: 0;
      left: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      // justify-content: space-evenly;
      gap: 10px;
      .btn {
        font-size: 13px;
        font-style: normal;
        border-radius: 5px !important;
        font-weight: 700;
        color: var(--bgInputBlack);
        padding: 8px 16px;
        border: none;
        height: auto;
        text-transform: uppercase;
        line-height: 24px;
        min-width: 95px;
        min-height: 32px;
        text-align: center;
        transition: 0.3s ease-in-out;
      }

      .reject-btn {
        background: #f45848;
      }

      .btnBorderGreen {
        background: var(--purplePrimary);
        &:hover {
          color: var(--bgDarkGray);
        }
      }

      .approved-btn {
        background: #39ff6c;
        &:hover {
          color: var(--bgDarkGray);
        }
      }

      button.btnBorderGreen {
        background: transparent;
        border: solid var(--mm-border-size) var(--purplePrimary);
        color: var(--purplePrimary);
        svg {
          width: 30px;
          height: 26px;
          position: relative;
          left: -8px;
          top: -3px;
        }
      }

      button.btnBorderGreen {
        &:hover {
          background: var(--purplePrimary) !important;
          color: var(--bgBlackLite);
        }
      }
    }
  }

  .loader {
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    text-align: center;
    right: 0;
    display: flex;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(0 0 0 / 62%);
  }

  .approved-popup {
    position: fixed;
    top: 50%;
    border-radius: 16px;
    left: 50%;
    box-shadow: 0px 18px 13px 0px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%);
    background: var(--bgDarkGray);
    width: 100%;
    max-width: 497px;
    padding: 30px 40px 50px 40px;
    text-align: center;

    .close-approved {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
    }

    .heading {
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 56px;
    }

    .sub-heading {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.preview-model-open {
  .link-wrapper {
    .borderBtn {
      position: relative;
      z-index: 9999;
    }
  }
}

.preview-model-dialog {
  position: fixed;
  bottom: 0;
  width: auto;
  height: 100%;
  right: 0;
  top: 0;
  left: 66px;
  display: inherit;
  max-width: initial;

  .modal-content {
    background: linear-gradient(
      92deg,
      rgb(26 26 26) 76%,
      rgba(26, 26, 26, 0) 100%
    );
    border: none;
    height: 100%;

    .statusFlex {
      font-family: "Cygnito Mono";

      &.loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column;
        z-index: 10;
        inset: 0;
        font-family: "Cygnito Mono";

        h3 {
          font-family: "Cygnito Mono";
        }
      }

      .modelPreview {
        height: calc(100vh - 250px) !important;
      }
    }

    .buttonBar {
      text-align: center;
      margin-top: 40px;

      button {
        margin: 0 10px;
      }
    }
  }
}

.customize-wrapper {
  display: flex;
  padding: 0 40px;
  width: 100%;
  justify-content: space-between;
  vertical-align: middle;
  /*height: 70%;*/

  .modelcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(100% - 336px);

    .cus-btn {
      position: fixed;
      bottom: 50px;
      z-index: 2;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .modeloader {
      height: 100vh !important;
      /*top: -59px;*/
    }
  }

  .controlcontainerOuter {
    transition: right 0.4s ease-in-out, opacity 0.4s ease-in-out;
    z-index: 1;
    position: absolute;
    width: 336px;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 2;

    // &::before {
    //   content: "";
    //   background: linear-gradient(1deg,
    //       var(--lightGray) 0%,
    //       rgba(26, 26, 26, 0) 100%);
    //   transition: 0.3s ease-in-out;
    //   width: 273px;
    //   height: 138px;
    //   display: inline-block;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 999;
    // }

    &:hover {
      &:before {
        display: none;
      }
    }

    .controlcontainer {
      height: 100%;
      background: var(--bgDarkGray);
      transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      position: relative;
      padding: 0 18px 0 23px;
      display: flex;
      flex-direction: column;

      .scrollbar-container {
        padding: 0;
        height: 100%;
        padding-right: 8px;
      }

      &:hover {
        &::before {
          transition: 0.3s ease-in-out;
          visibility: hidden;
        }
      }

      .ps__rail-y {
        padding: 0;
        background-color: var(--lightGray);
        width: 10px;
        border: solid var(--mm-border-size) var(--bgBlackLite);
        border-radius: 10px;
        opacity: 100;

        .ps__thumb-y {
          background-color: var(--green-ui);
          right: 1px;
          width: 6px;
        }
      }

      .sketch-picker-wrapper {
        position: fixed;
        transform: translate(0%, -50%);
        width: 257px !important;
        background: var(--bgInputBlack) !important;
        padding: 16px;

        @include effects-small-shadow;

        .color-picker-container {
          display: flex;
          flex-direction: column;

          width: 100%;

          .saturation-wrapper {
            width: 100%;
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;

            .pointer {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              transform: translate(-5px, -5px);
              background: rgb(248, 248, 248);
            }
          }

          .hue-wrapper {
            min-height: 10px;
            position: relative;
            margin: 12px 0 10px;

            .slider {
              width: 4px;
              border-radius: 1px;
              height: 10px;
              background: #fff;
              transform: translateX(-2px);
            }
          }

          .color-hex-box {
            background: var(--bgInputBlack);
            display: flex;
            height: 36px;
            flex-direction: row-reverse;
            width: 100%;
            align-items: center;

            input {
              background: var(--bgInputBlack);
              color: var(--OffWhite);
              border: none;
              font-size: 13px;
              height: 100%;
              margin-left: 15px;
              width: 100%;
            }

            span {
              margin-top: -5px;
              cursor: pointer;
              &:hover{
                color: var(--purplePrimary);
              }
            }
          }

          hr {
            margin: 5px 0 26px;
          }

          .recent-colors {
            display: flex;
            flex-direction: column;
            span {
              text-transform: uppercase;
              margin-bottom: 8px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .controlcontainer:-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--green-ui);
  }

  .controlcontainerOPEN {
    right: 0;
    opacity: 1;
    z-index: 101;
  }

  .controlcontainerLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: -380px;
    opacity: 0;
    transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;

    height: 100%;
    flex-direction: column;
    z-index: 1;
  }

  .controlcontainerLeftOPEN {
    left: 90px;
    opacity: 1;
  }

  .Collapsible__trigger {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    transition: 0.1s ease-in-out;
    line-height: normal;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    .iconRound {
      display: inline-block;
      margin-right: 16px;
      width: 18px;
      transition: 0.1s ease-in-out;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .iconEdit {
      display: inline-block;
      margin-right: 16px;
      width: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .Collapsible {
    border: solid var(--mm-border-size) var(--mm-colour-surface-400);
    border-radius: 5px;
    transition: 0.1s ease-in-out;
    padding: 15px;
    margin-bottom: 0.5rem;
    color: var(--OffWhite);
    height: auto;
    width: 100%;

    &.color-picker {
      .iconSelected {
        border-radius: 100px;
        overflow: hidden;
        height: 20px;
        border-radius: 100px;
        overflow: hidden;
        border: solid var(--mm-border-size) #dde3ff;
        width: 20px;
      }
    }

    &:not(.active):hover {
      border: solid var(--mm-border-size) var(--green-ui);
      background: var(--green-ui);
    }

    &.active {
      border: solid var(--mm-border-size) var(--green-ui);

      color: var(--lightGray);
    }

    &.active {
      .iconRound {
        transform: rotate(45deg);
        transition: 0.1s ease-in-out;
      }
    }
  }

  .CollapseMenus {
    transition: transform 0.1s ease-out, margin-top 0.1s ease-out,
      max-height 0.1s ease-out;
    transform: scaleY(1); // implicit, but good to specify explicitly
    transform-origin: top; // keep the top of the element in the same place. this is optional.

    .color-picker-mini {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--OffWhite);
      margin: 7px 0;

      .iconRound {
        display: inline-block;
        margin-right: 16px;
        width: 18px;
        transition: 0.1s ease-in-out;

        svg {
          height: 18px;
          width: 18px;
        }
      }

      .iconSelected {
        border-radius: 100px;
        overflow: hidden;
        height: 20px;
        border-radius: 100px;
        overflow: hidden;
        border: solid var(--mm-border-size) #dde3ff;
        width: 20px;
      }
    }
  }

  .EditorOpen {
    margin-top: 1rem;
    height: fit-content;
    transform: scaleY(1);
    border-top: 1px solid var(--mm-colour-surface-400);
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
    margin-bottom: -15px;

    :first-child:div {
      width: calc(100% - 20px);
    }
  }

  .EditorClosed {
    margin-top: 0rem;
    max-height: 0px;
    transform: scaleY(0);
  }

  .fileButtonBar {
    display: flex;
    gap: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
  }

  .fileButtonBar {
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 0;

      svg {
        margin-right: 16px;
      }
    }

    .file-input {
      display: none;
      font-size: 14px;
      width: 100%;
    }

    label {
      color: var(--purplePrimary);
      font-size: 13px;
      font-style: normal;
      border-radius: 10px;
      padding: 5px 0;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 16px;

      svg {
        margin-right: 12px;
      }
    }
  }

  .file-input::file-selector-button {
    content: "Upload Image";
    border: none;
    cursor: pointer;

    border-radius: 10px !important;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    background: var(--bgDarkGray);
    width: 100%;
    border: solid var(--mm-border-size) var(--green-ui);

    text-align: center;
    color: var(--green-ui);
    font-family: "DM Sans", sans-serif;
  }

  .file-input::file-selector-button:hover {
    background-color: rgba(0, 0, 0, 0.467);
  }

  .ImageControls {
    margin-top: 1rem;
    background: var(--bgInputBlack);
    border: solid var(--mm-border-size) var(--mm-colour-surface-400);
    position: fixed;
    padding: 13px;
    right: 15%;
    top: 50%;
    transform: translate(0%, -50%);
    width: 257px;

    .cancel-instance {
      position: absolute;
      cursor: pointer;
      right: 4px;
      top: -3px;
    }
  }

  .ImagePreview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;

    img {
      width: 100%;
      max-height: 10rem;
      object-fit: contain;
    }
  }

  .fileName {
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        height: 10px;
        margin-top: 4px;
        width: 10px;
      }
    }
  }

  .inputColorContainer {
    span {
      width: 100%;
      padding: 0;
      border-radius: 10px !important;
      height: 43px;
      border: solid var(--mm-border-size) #000;
    }
  }

  .ClearBlackButton {
    background: none;
    border: none;
    font-size: 14px;
    margin-top: -8px;
    /* identical to box height, or 100% */
  }

  .SliderFlex {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // flex-direction: row;
    margin-bottom: 0.5rem;
    position: relative;

    /*input[type="range"] {
      height: 3px;
      background-color: var(--mm-colour-surface-400);
      border: none;
      border-radius: 5px;
      //-webkit-appearance: none;
      width: 100%;
    }*/
    input[type="range"] {
      width: 100%;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px; /* Height of the track */
      background-color: var(--mm-colour-surface-400); /* Color of the track */
      border-radius: 5px;
      border: none; /* Remove default border */
    }

    input[type="range"]::-webkit-slider-thumb {
      appearance: none; /* Remove default styling */
      width: 10px; /* Width of the thumb */
      height: 10px; /* Height of the thumb */
      background-color: var(--green-ui); /* Color of the thumb */
      //border: 2px solid #fff; /* Border around the thumb */
      border: none; /* Make it round */
      cursor: pointer; /* Change cursor on hover */
      margin-top: -3px; /* Center vertically */
    }

    /*
    input[type="range"]::-webkit-slider-thumb {
      //-webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background-color: var(--green-ui);
      border-radius: 50%;
      cursor: pointer;
    }
    */

    span {
      width: auto;
      text-align: right;
      color: var(--OffWhite);
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      background: var(--Dark333);
      position: absolute;
      top: 4px;
      right: 0;
      border: solid var(--mm-border-size) var(--mm-colour-surface-400);
      padding: 3px 7px;
      cursor: pointer;

      &:focus {
        border: solid var(--mm-border-size) var(--purplePrimary);
      }
    }

    label {
      width: 4rem;
      text-align: left;
      color: var(--OffWhite);
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }

    input {
      width: 12rem;
    }
  }

  .project-detail {
    display: flex;
    padding: 16px 0;
    align-items: center;

    .img-block {
      width: 52px;
      height: 66px;
      margin-right: 15px;

      img {
        object-fit: contain;
        border-radius: 5px;
        width: 52px;
        padding: 1px;
        border-radius: 5px;
        overflow: hidden;
        background: var(--grayD7D7);
        height: 66px;
        object-fit: cover;
      }
    }

    .data-block {
      padding-right: 15px;

      h4 {
        font-family: Satoshi;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin: 0;
        color: var(--OffWhite);
      }

      span {
        font-family: Satoshi;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        color: var(--lightGray);
      }
    }
  }
}

.toggleControlPannel {
  display: none;
}

@media (max-width: 1300px) {
  .template-design-main {
    .topbar {
      .link-wrapper {
        display: flex;

        .button-wrapper {
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .template-design-main {
    .topbar {
      .link-wrapper {
        display: flex;

        .button-wrapper {
          margin-top: 0px;
        }
      }
    }
  }

  .customize-wrapper {
    .controlcontainerOuter {
      .toggleControlPannel {
        display: inline-block;
        font-size: 0;
        position: absolute;
        height: 15px;
        margin-bottom: 30px;
        top: 26px;
        right: 30px;
        width: 30px;
        z-index: 9;
        cursor: pointer;

        &::after {
          content: "";
          width: 30px;
          height: 2px;
          background: var(--purplePrimary);
          position: absolute;
          left: 0;
          bottom: 0;
        }

        &::before {
          content: "";
          width: 30px;
          height: 2px;
          background: var(--purplePrimary);
          position: absolute;
          left: 0;
          top: 0;
        }
      }

     &.open{
      transition: 0.3s ease-in-out;
.toggleControlPannel{
      &::after { transition: 0.3s ease-in-out;
    transform: translateY(13px) rotate(45deg);
      }

      &::before { transition: 0.3s ease-in-out;
        transform: translateY(26px) rotate(-43deg);
            }
          }
     }



      .controlcontainerOPEN {
        display: none;
      }

      .scrollbar-container {
        // position: absolute;
        right: 0;
        // width: 250px;
        // top: 50px;
      }
    }
  }

  .link-wrapper .template-design-main {
    .template-heading {
      &:after {
        width: 50px;
        opacity: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .btnBorderGreen {
    height: 40px;
    font-size: 14px;
  }

  .preview-model-dialog {
    .modal-content {
      .buttonBar {
        button {
          margin: 10px;
        }
      }
    }
  }

  .topbar {
    .link-wrapper {
      padding: 0 20px;
      // .top-links{width: 100%;}
    }
  }

  .customize-wrapper {
    .modelcontainer {
      width: 100%;
    }
  }

  .template-design-main {
    .template-heading {
      &:after {
        width: 0;
      }
    }
  }

  .preview-model-dialog {
    top: -18px;
    left: 76px;
  }

  .template-wrapper {
    .template-Outer {
      padding: 0 20px;
      margin-bottom: 30px;
    }
  }

}

.loader {
  h3 {
    font-family: "Cygnito Mono";
  }
}

.custom-hue-picker {
  position: relative;
  width: 100%;
  height: 16px;
  cursor: pointer;
}

.hue-gradient {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.hue-pointer {
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 3px solid #fff;
  border-radius: 8px;
  cursor: pointer;
}

.hue-pointer.dragging {
  cursor: grabbing;
}
