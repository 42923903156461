.dashboard-container {
  .template-heading {
    padding-bottom: 36px;
    align-items: center;

    h3 {
      font-size: 48px;
      margin-bottom: 0;

      @media (max-width:758px) {
        font-size: 36px;
      }

      @media (max-width:680px) {
        font-size: 36px;
      }

    }

    .cta-button-wrapper {
      .cta-button {
        @media (max-width:680px) {
          font-size: 0px;
        }

        svg {
          @media (max-width:680px) {
            margin-right: 0 !important;
          }
        }
      }

    }


  }


  .common-card-bx {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    column-gap: 78px;
    margin-bottom: 24px;

    @media (min-width: 320px) {
      width: 100%;
      flex-wrap: wrap;
    }

    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      column-gap: 24px;
      width: 100%;
      flex-wrap: nowrap;
    }

    @media (min-width: 1280px) {
      column-gap: 78px;
      width: 100%;
    }

    & h6 {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      align-items: center;
      font-weight: 400;
      color: var(--OffWhite);

      & a {
        color: var(--OffWhite);
        text-decoration: underline;
        font-size: 16px;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
          color: var(--purplePrimary);
        }
      }
    }

    .no-project-sec {
      width: 100%;
      text-align: left;
      color: var(--OffWhite);
      border-radius: 6px;
      background-color: var(--mm-colour-surface-500);
      padding: 30px;

      h6 {
        font-size: 16px;
      }

      .no-project-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;

        .rainbow-btn {
          svg {
            display: inline-block;
            margin-right: 6px;
            margin-top: -6px;
          }
        }

      }

      .data-heading {
        color: var(--OffWhite);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 20px;

        @media (min-width: 991px) {
          line-height: 4;
          margin-bottom: 0;
        }
      }

      .data-content {
        text-align: center;
        font-family: Void;
        font-size: calc(10vw + 10px);
        font-style: normal;
        font-weight: 700;
        line-height: 1;

        @media (max-width:991px) {
          font-size: calc(6vw + 10px);
        }
      }

      .action-wrapper {
        margin-top: 30px;

        .clear-filter-link {
          &:hover {
            color: var(--purplePrimary);
          }
        }
      }
    }

    .card-grid-block {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(6, 1fr);

      @media (max-width: 1673px) {
        grid-template-columns: repeat(4, 1fr);
      }


      @media (max-width: 1536px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);


      }

      .project-block {
        aspect-ratio: 2.37/3;
        width: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
        border: solid var(--mm-border-size) transparent;
        cursor: pointer;
        display: block;

        .checkbox-group {
          position: absolute;
          top: 8px;
          left: 8px;
          right: 8px;
          z-index: 2;
          height: 16px;
          display: flex;
          flex-direction: row-reverse;

        }

        &:hover {
          border: solid var(--mm-border-size) var(--purplePrimary);

        }

        .table-data {
          height: 100%;
          margin: 1px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
          }
        }

        .cta-button {
          background: radial-gradient(206.89% 173.7% at 56.82% 148.44%,
              #6161ff 11.46%,
              #dbb6ff 34.38%,
              #ffc68c 58.85%,
              #9cffb1 84.38%);
          border-radius: 50px;
          position: absolute;
          border: solid var(--mm-border-size) #000;
          padding: 6px;
          color: var(--bgDarkGray);
          display: flex;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          margin: 8px;
          right: 0;
          align-items: center;

          svg {
            margin-right: 10px;
            max-width: 8px;
            width: 100%;
          }
        }

        .project-detail {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          padding: 10px;
          opacity: 0.8;
          background-color: var(--bgInputBlack);
          display: flex;
          flex-direction: column;

          a {
            color: var(--OffWhite);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: capitalize;

            &.project-link {
              position: relative;
            }
          }

          span {
            color: var(--OffWhite);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: capitalize;
            transition: 0.3s ease-in-out;
          }
        }

        @media (min-width: 576px) {
          width: 100%;
        }
      }
    }



    .recent-project-sec {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      border-radius: 6px;
      background-color: var(--mm-colour-surface-500);
      padding: 24px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      .card-grid-block {
        grid-template-columns: repeat(6, 1fr);


        @media (max-width: 1536px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 1280px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);

        }
      }

      .project-block {
        transition: 0.3s ease-in-out;

        &:hover {
          border: solid var(--mm-border-size) var(--purplePrimary);

        }
      }
    }

    .recent-card-inner {
      width: 60%;
      display: flex;

      .project-block {
        @media (min-width: 320px) {
          width: 100%;
        }
      }
    }
  }

  .recent-project-sec {
    display: flex;
    flex-direction: column;
  }

  // new item
  .dashboard-mid {
    column-gap: 24px;
    flex-direction: row;

    .section {
      width: 50%;
      text-align: left;
      color: var(--OffWhite);
      border-radius: 6px;
      background-color: var(--mm-colour-surface-600, #0A0C10);
      border: 1px solid var(--mm-colour-surface-400, #3E4046);
      padding: 24px;

      .section-header {
        display: flex;
        align-items: center;

        h6 {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

        }

        .version-text {
          margin-left: 20px;
          font-size: 13px;
        }
      }

      .text-header {
        font-size: 16px;
        margin-left: 0px;
        color: var(--OffWhite);
        cursor: pointer;
        width: 100%;

        &:hover {
          color: var(--purplePrimary);
          text-decoration: underline;
        }
      }

      .list-container {
        height: 64px;
        display: flex;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid var(--gray333);
        align-items: center;
        justify-content: flex-start;

        .status-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 5px;
          height: 32px;
          width: 64px;

          svg {
            max-width: 8px;
            width: 100%;
          }

          .btn-text {
            flex: 3;
            color: var(--bgDarkGray);
            font-size: 13px;

            text-align: center;
          }
        }

        .red-button {
          background-color: var(--redText);
        }
      }

      .faq-list-block {
        height: 64px;
        display: flex;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid var(--mm-colour-surface-400);
        align-items: center;
        justify-content: flex-start;
      }

    }

    .slide-right-area {
      width: 50%;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 6px;
      background-color: var(--mm-colour-surface-600, #0A0C10);
      border: 1px solid var(--mm-colour-surface-400, #3E4046);

      padding: 24px;

      @media (max-width: 1702px) {
        width: calc(50% - 12px);
      }

      .slider-top {
        h6 {
          color: var(--bs-heading-color);
          font-size: 16px;
          margin-bottom: 0.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .slider-content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 767px) {
            flex-wrap: wrap;
          }

          & span {
            font-size: 13px;
          }

          .select-btn {
            border: solid var(--mm-border-size) var(--green-ui);
            border-radius: 5px !important;
            background: transparent;
            font-style: normal;
            font-size: 13px;
            line-height: 16px;
            background-color: var(--green-ui);
            text-align: center;
            color: var(--bgInputBlack);
            padding: 7px 20px;
            font-weight: 700;
            min-width: 110px;

            &:hover {
              background-color: var(--lightGray);
              color: var(--bgInputBlack);
            }
          }
        }
      }

      .slider-middle {
        .slider-block {
          align-items: center;
          gap: 10px;

          @media (max-width: 767px) {
            flex-direction: column-reverse;
          }

          .content {
            width: 50%;
            padding-left: 5px;

            @media (max-width: 767px) {
              width: 100%;
            }

            .details {
              font-size: 13px;
            }
          }

          .slider-image {
            width: 50%;
            padding-right: 2px;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

      }

      .slider-middle-bottom {
        padding: 1rem;
        border-top: 1px solid var(--gray333);

        h2 {
          color: var(--lightGray);
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 1023px) {
      .slide-right-area {
        width: 100%;
      }

      .faq-section {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .footer-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    color: var(--whitColor);

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .section {
      width: 50%;
      padding: 10px;
      text-align: left;
      color: var(--OffWhite);
      border-radius: 6px;
      padding: 24px;

      background-color: var(--mm-colour-surface-600, #0A0C10);
      border: 1px solid var(--mm-colour-surface-400, #3E4046);

      @media (max-width: 1023px) {
        width: 100%;
      }

      .section-header {
        display: flex;
        align-items: center;

        h6 {
          font-size: 16px;
          color: var(--OffWhite);
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .version-text {
          margin-left: 20px;
          font-size: 13px;
        }
      }

      .text-header {
        font-size: 16px;
        margin-left: 8px;
        color: var(--OffWhite);
        cursor: pointer;
        width: 100%;

        &:hover {
          color: var(--purplePrimary);
          text-decoration: underline;
        }
      }

      .list-container {
        height: 64px;
        display: flex;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid var(--mm-colour-surface-400);
        align-items: center;
        justify-content: flex-start;

        /*&:hover {
          cursor: pointer;

          .purple-button {
            background-color: var(--purplePrimary);
          }

          .text-header {
            color: var(--purplePrimary);
            text-decoration: underline;
          }
        }*/

        .status-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 5px;
          height: 32px;
          width: 64px;

          svg {
            max-width: 8px;
            width: 100%;
          }

          .btn-text {
            flex: 3;
            color: var(--bgDarkGray);
            font-size: 13px;

            text-align: center;
          }
        }

        .purple-button {
          background-color: var(--lightPurple);

          &:hover {
            background-color: var(--purplePrimary);
          }
        }
      }

      .faq-list-block {
        height: 64px;
        display: flex;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid var(--gray333);
        align-items: center;
        justify-content: flex-start;
      }


      &.product-link-sec {
        background-color: var(--purplePrimary);
        cursor: pointer;
        color: var(--bgInputBlack);

        h6 {
          font-size: 16px;
          font-weight: 400;
          color: var(--bgInputBlack);
        }

        .product-link-inner {
          max-width: 468px;
          margin: 86px auto 0;
          text-align: center;

          hr {
            border-color: var(--gray333);
            margin: 2rem 0;
          }

          .orgnization-block h6 {
            font-size: 24px;
            width: 100%;
            text-align: center;
            display: block;
          }

          label {
            font-size: 12px;
          }

          .model-version {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }
    }

    .section h2 {
      font-size: 18px;
    }
  }
}



.project-list-container {
  .template-heading {
    padding-bottom: 36px;
    align-items: center;

    h3 {
      font-size: 48px;
      margin-bottom: 0;


    }
  }
}

.project-list-container.recent-designed-sec {
  .action-row {
    top: 70px !important;
    padding-top: 30px !important;
  }

  .project-list-grid {
    .sort-row {
      display: none;
    }

    .grid-block {
      padding-left: 24px !important;
      padding-right: 24px !important;
      row-gap: 22px !important;
      column-gap: 16px !important;

      .project-block {
        .table-data {
          .project-detail {
            a.project-link {
              font-size: 16px;
              font-weight: 400;
            }

            span {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        @media (min-width: 576px) {
          width: calc(50% - 13px);
        }

        @media (min-width: 768px) {
          width: calc(33.33% - 13px);
        }

        @media (min-width: 1024px) {
          width: calc(25% - 13px);
        }

        @media (min-width: 1280px) {
          width: calc(20% - 13px);
        }


        &:hover {
          border: solid var(--mm-border-size) var(--purplePrimary);


        }


      }
    }
  }

  .item-list-container {
    background-color: var(--mm-colour-surface-600, #0A0C10);
    border: 1px solid var(--mm-colour-surface-400, #3E4046);
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 6px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 20px;

    .action-row {
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 6px 6px 0 0;
      padding-bottom: 0px;

      top: 70px !important;
      padding-top: 24px !important;

      .heading {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h3 {
          font-size: 16px;
          margin-bottom: 0;
        }

        h6 {
          font-size: 16px;
          font-weight: 400;
          color: var(--OffWhite);
          margin-bottom: 0;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .dropdown {
          margin-left: 30px;
          font-size: 13px;
          text-transform: capitalize;

          @media (max-width: 767px) {
            width: 50%;
            margin-left: 0px;
          }

          .dropdown-toggle {
            display: flex;
            align-items: center;

            &.show {
              color: var(--purplePrimary);

              svg {
                transform: rotate(180deg);
              }
            }

            &:hover {
              color: var(--purplePrimary);
              cursor: pointer;
            }

            &::after {
              display: none;
            }

            svg {
              width: 9px;
              margin-left: 5px;
            }
          }

          .dropdown-menu {
            width: 253px;
            background-color: var(--mm-colour-surface-500);
            border-color: var(--mm-colour-surface-400);
          }
        }

        .right-actions {
          margin-left: auto;

          & a {
            color: var(--OffWhite);
            text-decoration: underline;
            font-size: 16px;
            font-weight: 400;

            &:hover {
              text-decoration: underline;
              color: var(--purplePrimary);
            }
          }
        }
      }
    }
  }
}

.project-list-container.recent-projects-sec {
  .action-row {
    top: 70px !important;
    padding-top: 30px !important;
  }

  .project-list-grid {
    .sort-row {
      display: none;
    }

    .grid-block {
      padding-left: 24px !important;
      padding-right: 24px !important;
      row-gap: 22px !important;
      column-gap: 16px !important;

      .project-block {

        .table-data {
          .project-detail {
            a.project-link {
              font-size: 16px;
              font-weight: 400;
            }

            span {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }


        @media (min-width: 576px) {
          width: calc(50% - 13px);
        }

        @media (min-width: 768px) {
          width: calc(33.33% - 13px);
        }

        @media (min-width: 1024px) {
          width: calc(25% - 13px);
        }

        @media (min-width: 1280px) {
          width: calc(20% - 13px);
        }

        &:hover {
          border: solid var(--mm-border-size) var(--purplePrimary);

        }

      }
    }
  }

  .item-list-container {
    background-color: var(--mm-colour-surface-600, #0A0C10);
    border: 1px solid var(--mm-colour-surface-400, #3E4046);
    margin: 24px;
    border-radius: 6px;
    padding-bottom: 20px;

    .action-row {
      padding-left: 24px;
      border-radius: 6px 6px 0 0;
      padding-right: 24px;
      top: 70px !important;
      padding-top: 24px !important;
      padding-bottom: 0px;


      .heading {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h6 {
          font-size: 16px;
          margin-bottom: 0;
          color: var(--OffWhite);
          font-weight: 400;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .dropdown {
          margin-left: 30px;
          font-size: 13px;
          text-transform: capitalize;

          @media (max-width: 767px) {
            margin-left: 0px;
            width: 50%;
          }

          .dropdown-toggle {
            display: flex;
            align-items: center;

            &.show {
              color: var(--purplePrimary);

              svg {
                transform: rotate(180deg);
              }
            }

            &:hover {
              color: var(--purplePrimary);
              cursor: pointer;
            }

            &::after {
              display: none;
            }

            svg {
              width: 9px;
              margin-left: 5px;
            }
          }

          .dropdown-menu {
            width: 253px;
          }
        }

        .right-actions {
          margin-left: auto;

          & a {
            color: var(--OffWhite);
            text-decoration: underline;
            font-size: 16px;
            font-weight: 400;

            &:hover {
              text-decoration: underline;
              color: var(--purplePrimary);
            }
          }
        }
      }
    }
  }
}

.new-updates-container {
  .list-container {
    height: 64px;
    display: flex;
    margin: 10px 24px;
    border-bottom: 1px solid var(--gray333);
    align-items: center;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;

      .purple-button {
        background-color: var(--purplePrimary);
      }

      .text-header {
        color: var(--purplePrimary);
        text-decoration: underline;
      }
    }

    .status-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 5px;
      height: 32px;
      width: 64px;

      svg {
        max-width: 8px;
        width: 100%;
      }

      .btn-text {
        flex: 3;
        color: var(--bgDarkGray);
        font-size: 13px;

        text-align: center;
      }
    }

    .purple-button {
      background-color: var(--lightPurple);

      &:hover {
        background-color: var(--purplePrimary);
      }
    }
  }

  .faq-container {
    .list-container {
      height: 64px;
      display: flex;
      margin: 10px 30px;
      border-bottom: 1px solid var(--gray333);
      align-items: center;
      justify-content: flex-start;

      .status-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 5px;
        height: 32px;
        width: 64px;

        svg {
          max-width: 8px;
          width: 100%;
        }

        .btn-text {
          flex: 3;
          color: var(--bgDarkGray);
          font-size: 13px;

          text-align: center;
        }
      }

      .purple-button {
        background-color: var(--lightPurple);

        &:hover {
          background-color: var(--purplePrimary);
        }
      }
    }
  }

  .text-header {
    font-size: 16px;
    margin-left: 8px;
    color: var(--OffWhite);
    width: 100%;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: var(--purplePrimary);
    }
  }
}

// new item page
.new-items-container {
  .content-wrapper {
    position: relative;
    display: grid;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 48px;
    row-gap: 30px;

    @media (max-width: 1120px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }


  }
}


// TEMPLATE CARDS

.new-item-sec {
  position: relative;
  z-index: 0;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 0px;
  //aspect-ratio: 2 / 2.5;
  border: solid var(--mm-border-size) var(--gray333);
  transition: 0.3s ease-in-out;
  background: #000;

  .lockIcon{
    position: absolute;
    top: 1rem;
    right: 1rem;
    max-width: 10%;
    z-index: 1;
  }

  &:hover {
    border-color: var(--purplePrimary);

    .new-item-bx {
      border-color: var(--purplePrimary);
      background-color: var(--bgInputBlack);
    }
  }

  .new-item-bx-show {
    position: absolute;
    bottom: -1px;
    padding: 20px 5px 11px 21px;
    left: 0px;
  }

  .new-item-image {
    border-radius: 5px;
    pointer-events: none !important;
    mix-blend-mode: normal;
    border-bottom: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .grayscale{
    filter: grayscale(100);
  }

  .new-item-bx {
    border-radius: 0px 0px 5px 5px;
    padding: 20px 5px 10px 20px;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    bottom: 0;
    // transition: 0.3s ease-in-out;
    left: 0;

    right: 0;
    opacity: 0.8000000119;
    background-color: var(--bgInputBlack);
    border-top: var(--mm-border-size) solid var(--gray333);

    &:hover {
      border-color: var(--purplePrimary);
    }

    .new-item-bx-description {
      max-height: 90%;
      overflow: hidden;
      padding-right: 20px;

      h4 {
        color: var(--mm-colour-surface-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @media (max-width: 1359px) {
          font-size: 16px;
          line-height: normal;
        }

        @media (max-width: 1199px) {
          font-size: 16px;
        }
      }
    }

    .new-item-bx-bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }
  }

  &.active {
    border-color: var(--purplePrimary);

    .new-item-bx {
      border-top: none;
      border-color: var(--purplePrimary);
      background-color: var(--bgInputBlack);
    }
  }

  .model-detail {
    border-radius: 5px;
    padding: 16px;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    //background: var(--bgDarkGray);
    &.open {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // background: #070707e0;
      background: rgb(24 24 25 / 70%);
      opacity: 100%;
      padding-top: 32px;
      padding-bottom: 40px;
      border-color: var(--purplePrimary);

      .scrollbar-container {
        padding-bottom: 15px;
      }

    }

    h6 {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-transform: capitalize;
    }

    .sub-heading {
      font-size: 14px;
      font-weight: 400;
      color: var(--lightPurple);
      margin-bottom: 8px;
    }

    p.sub-heading-desc {
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 400;
      color: var(--mm-colour-surface-100);
    }

    .description,
    .features {
      font-size: 12px;

      p {
        color: var(--mm-colour-surface-100);
      }

      ul {
        padding-left: 16px;
        color: var(--mm-colour-surface-100);
      }
    }
  }
}

.dashboard-card {
  width: 100%;
  background-color: var(--mm-colour-surface-600, #0A0C10);
  border: 1px solid var(--mm-colour-surface-400, #3E4046);
  padding: 24px;
  border-radius: 6px;
  margin-top: 24px;
}

.MM-swiper-ui {
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :first-child {
    margin-right: 12px;
  }

  :last-child {
    margin-left: 12px;
  }

  span {
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.MM-swiper-button {
  font-family: swiper-icons;
  font-size: var(--bs-body-font-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: var(--purplePrimary);
  font-weight: 700;

  border: none;
  background: none;

  &:disabled {
    opacity: 0.35;
  }
}