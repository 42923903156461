.item-dropdown,
.dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  border: solid var(--mm-border-size) var(--mm-colour-surface-400);
  border-radius: 2px;
  background-color: var(--bgDarkGray);
  position: absolute;
  box-shadow: 0px 8px 28px rgba(20, 20, 43, 0.1);
  padding: 5px;
  z-index: 1;
  top: 57px;
  color: var(--OffWhite) !important;
  border-radius: 5px;
  display: none;

  div:not(.dropdown-wrapper),
  a {
    &:hover {
      background-color: var(--purplePrimary);
      color: var(--bgDarkGray);
    }
  }

  p {
    cursor: pointer;
  }

  .dropdown-wrapper {
    max-height: 200px;
    overscroll-behavior: contain;
    overflow: auto !important;
  }
}


.dropdown {
    font-size: 13px;
    text-transform: capitalize;

    .dropdown-toggle {
        display: flex;
        align-items: center;
        gap: 6px;

        &.show {
            color: var(--purplePrimary);

            svg {
                transform: rotate(180deg);
            }
            .status-circle{
              border-color: var(--purplePrimary) !important;
            }
        }

        &:hover {
            color: var(--purplePrimary);
            cursor: pointer;

            .status-circle{
              border-color: var(--purplePrimary) !important;
            }
        }

        &::after {
            display: none;
        }

        svg {
            width: 9px;
            margin-left: 5px;
        }
    }

    .dropdown-menu {
        /*width: 253px;*/
        background-color: var(--mm-colour-surface-500);
        border-color: var(--mm-colour-surface-400);
    }

}

.item-dropdown-details-active {
    background-color: var(--purplePrimary);
    color: var(--bgDarkGray);
}



.dropdown-item-text {
    color: var(--mm-colour-surface-300);
    @include mm-text-label;
    padding: 5px 10px;
}

.dropdown-item {
    display: flex;
    justify-content: flex-start;
    text-transform: capitalize;
    padding: 5px 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    color: var(--OffWhite);
    border-radius: 5px;
    line-height: 24px;
    cursor: pointer;
    align-items: center;
    gap: 12px;

    svg{
        //margin-right: 12px;
    }
  
    &.search-wrapper {
      &:hover {
        background-color: unset !important;
      }
      position: relative;
      padding: 0 10px;
      margin-top: 10px;
  
      span {
        position: absolute;
        left: 13px;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--bgDarkGray);
      }
      .search-input {
        width: 100%;
        height: 38px;
        padding-left: 25px;
        border-radius: 5px;
        border: none;
        background: var(--purplePrimary);
        color: var(--bgBlackLite);
        &::placeholder {
          color: var(--bgBlackLite);
          opacity: 0.5;
        }
  
        &:active,
        &:focus {
          border: none;
        }
      }
    }
  
    &.disabled,
    &:disabled {
      color: var(--borderGray);
    }
  
    &.dropdown-heading {
      color: var(--lightPurple);
      font-family: Satoshi;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
    }
    &.dropdown-heading:hover {
      background-color: unset;
    }
  }
  
  .dropdown-divider {
      color: var(--mm-colour-surface-400);
      border: solid var(--mm-border-size) var(--mm-colour-surface-400);
      margin-block: 4px;
  }
  

  .item-dropdown-filter-active {
    color: var(--purplePrimary);
    svg{
        color: var(--purplePrimary);
    }
    
}

.filter-button {
  background: none;
  border: none;
  padding-left: 0px;
  padding-right: 12px;

  &:hover {
    background-color: var(--bgDarkGray);
    color: var(--bgDarkGray);
  }

  &:active {
    background-color: var(--bgDarkGray) !important;
  }

  &.show {
    background-color: var(--bgDarkGray);
    color: var(--bgDarkGray);
  }
}

.expand-button{
  background: none;
  border: none;
}

.template-card-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.un-selected {
  opacity: .3;
}

.name-input {
  input {
    width: 100%;
    border-radius: 5px;
    height: 35px;
    margin-bottom: 10px;
    padding: 0 13px;
    border: solid var(--mm-border-size) #cdcdcd;
  }

  .text-danger {
    position: relative;
    top: -9px;
  }
}

.close-preview {
  position: absolute;
  right: 0;
  top: 0;
  color: #FFF;
  background: transparent;
  border: none;
  font-size: 25px;
  right: 9px;

  span {
    color: #FFF;
  }
}

.design-select-btn{
    border: solid var(--mm-border-size) var(--green-ui);
    border-radius: 5px !important;
    background: transparent;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: var(--green-ui);
    padding: 7px 20px;
    font-weight: 700;
    min-width: 110px;

    &:hover {
      background-color: var(--green-ui);
      color: var(--bgInputBlack);
    }
}