.member-list-container {
  height: 100vh;
  padding: 20px;
  overflow: auto;
  position: relative;
  // background-color: var(--main-color);

  .list-header {
    padding-left: 24px;
    max-width: 100%;
    background: var(--bgDarkGray);
    .list-description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
      padding: 54px 0 8px 0;
    }
  }



  .template-heading-secondary {
    padding:36px 24px 24px;
    // margin-top: 36px;
    // margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    background:var(--bgDarkGray);
    span{
      cursor: pointer;
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
    }
  }

  .member-list-table {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    // overflow: auto;

    .table-head {
      border-bottom: 1px solid var(--mm-colour-surface-400);
      border-top: 1px solid var(--mm-colour-surface-400);
      background: var(--bgDarkGray);

      .table-row {
        display: grid;
        grid-template-columns: 0.4fr 0.2fr 0.2fr 0.2fr;
        padding: 0.5rem 22px;
        min-height: 57px;
        // color: var(--OffWhite);//

        &:not(.no-border):not(:last-child) {
          border-bottom: 1px solid var(--borderGray);
        }

        .table-heading {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-transform: capitalize;
        }
      }
    }

    .table-body {
      .table-row {
        display: grid;
        grid-template-columns: 0.4fr 0.2fr 0.2fr 0.2fr;
        padding: 0.5rem 22px;
        min-height: 57px;
        font-size: 14px;
        &:not(:last-child) {
          border-bottom: 1px solid var(--mm-colour-surface-400);
        }

        &:not(:last-child):hover {
          border-bottom: 1px solid var(--green-ui);

          .shortName {
            border: solid var(--mm-border-size) var(--green-ui);
          }
        }

        &:last-child:hover {
          border-bottom: 1px solid var(--green-ui);

          .shortName {
            border: solid var(--mm-border-size) var(--green-ui);
          }
        }

        &:hover {
          .table-data {
            color: var(--green-ui);
            
            .dropdown {
              .dropdown-toggle {
                &:after {
                  border-top: 2px solid var(--green-ui) !important;
                  border-left: 2px solid var(--green-ui) !important;
                }
              }
            }

            .titleGroup {
              .name {
                color: #fff;
              }

              .dropdown-toggle {
                color: #fff;

                &::after {
                  border-top: 2px solid #fff !important;
                  border-left: 2px solid #fff !important;
                }
              }
            }

          }
        }

        .table-data {
          display: flex;
          align-items: center;
          color: var(--OffWhite);

          img {
            width: 43px;
            height: 43px;
            margin-right: 0.5rem;
          }

          .project-link {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .table-data {

          .titleGroup {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            font-size: 14px;

            .name {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            .email {
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 21px;
              /*text-transform: uppercase;*/
            }

            .dropdown-toggle {
              display: flex;
              align-items: center;
              cursor: pointer;

              span {
                margin-right: 8px;
              }

              &::after {
                content: none !important;
              }

              svg {
                color: #F2F5F8;
              }

              &:hover {
                svg {
                  color: var(--green-ui);
                }
              }
            }
          }

        }
      }
    }
  }



  .pagination-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    .pagination-text {
      color: #000000;
      font-size: 15px;
      font-weight: 600;
    }

    .pagination-btn {
      border: none;
      padding: 5px 10px;
      font-size: 20px;
      outline: none;
      border-radius: 5px;
    }
  }
}

.no-project-screen {
  height: calc(100% - 190px);

  .no-project-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
  }

  .data-heading {
    color: var(--OffWhite);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: 991px) {
      line-height: 4;
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .data-content {
    text-align: center;
    font-family: Void;
    font-size: calc(10vw + 10px);
    font-style: normal;
    font-weight: 700;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 140px;
    }

    @media (min-width: 1280px) {
      font-size: 175px;
    }
  }

  .action-wrapper {
    margin-top: 50px;
  }
}

.add-new-user {
  .name-header {
    justify-content: center;
    height: 90px;
    display: flex;
    width: 90px;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 30px;
    margin-top: 15px;
  }
}





@media (max-width: 1024px) {
  .members-main {
    .topbar {
      .link-wrapper {
        .button-wrapper {
          margin-top: -36px;
        }
      }
    }
  }
}

/*1024px*/

@media (max-width: 767px) {
  .members-main {
    .topbar {
      .link-wrapper {
        .button-wrapper {
          margin-top: 0;
        }
      }
    }
  }

  .member-list-container {
    .list-header {
      padding-left: 20px;

      h2 {
        padding: 18px 0 8px 0;
      }
    }
  }
}

.shortName {
  width: 42px;
  height: 42px;
  border-radius: 100px;
  display: inline-block;
  border: solid var(--mm-border-size);
  text-align: center;
  line-height: 37px;
  /*margin-right: 15px;*/
  min-width: 42px;
}