.swatch-picker{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 4px;

    .swatch{
        margin: 0px;
        width: 23px;
        height: 23px;
        border: solid var(--mm-border-size) var(--mm-colour-surface-400, #333);
        cursor: pointer;
    }
}