.project-list-container {
  height: 100vh;
  padding: 20px;
  overflow: auto;
  position: relative;
  // background-color: var(--main-color);

  .action-row {
    padding: 0 24px 16px;
    margin-bottom: 0;
    background: var(--bgDarkGray);
  }

  .project-list-table {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border-top: 1px solid var(--mm-colour-surface-400);

    .table-head {
      border-bottom: 1px solid var(--mm-colour-surface-400);

      background: var(--bgDarkGray);

      .table-row {
        display: grid;
        grid-template-columns: 0.35fr 0.25fr 0.2fr 0.2fr;

        padding-left: 12px;
        padding-right: 0px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        min-height: 57px;

        &:not(.no-border):not(:last-child) {
          border-bottom: 1px solid var(--borderGray);
        }

        .table-heading {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .table-body {
      .table-row {
        display: grid;
        grid-template-columns: 0.35fr 0.25fr 0.2fr 0.2fr;
        padding-left: 12px;
        padding-right: 0px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        min-height: 60px;
        font-size: 14px;


        &:hover {
          background-color: #131314;
        }

        &.selected {
          .table-data {
            color: var(--purplePrimary);

            .action-btn {
              color: var(--purplePrimary);

              border: solid var(--mm-border-size) var(--purplePrimary);

              svg path {
                fill: var(--purplePrimary);
                transition: 0.3s ease-in-out;
              }
            }

            .project-link {
              color: var(--purplePrimary);
            }
          }
        }

        &.clicked {
          background-color: var(--purplePrimary);

          .table-data {
            color: var(--bgInputBlack);

            a {
              color: var(--bgInputBlack);
            }

            .checkbox-group {
              input[type="checkbox"] {
                &.gray {
                  &+label {
                    &::before {
                      border-color: var(--bgInputBlack);
                    }
                  }

                  &:checked {
                    &+label {
                      &::before {
                        background-image: url("../public/checked-black.svg");
                      }
                    }
                  }
                }
              }

              &:hover {
                background-color: transparent;
              }
            }
          }

          &:hover {
            background-color: var(--purplePrimary) !important;
          }
        }

        &.add-project {
          background: var(--green-ui);
          justify-content: start !important;
          align-items: center;
          color: var(--bgBlackLite);
          padding-left: 75px;
          font-weight: 500;
          cursor: pointer;

          span {
            margin-left: 10px;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--mm-colour-surface-400);
        }

        &:not(:last-child):hover {
          // border-bottom: 1px solid var(--green-ui);
        }

        .table-data {
          display: flex;
          align-items: center;
          flex-direction: row;
          color: var(--OffWhite);
          font-weight: 400;
          transition: 0.3s ease-in-out;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          gap: 8px;

          /*:nth-child(n){
            flex-grow: 1;
          }*/

          /*
          :last-child{
            flex-grow: 1;
          }
          */

          /*
          span{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          */

          .checkbox-group {
            width: 40px;
            height: 40px;
            /*padding: 13px 0 0 13px;
            border-radius: 100px;*/

            &:hover {
              background-color: #333747;
            }
          }

          .user-image {
            max-width: 43px;
            height: 43px;
            border-radius: 100px;
            object-fit: cover;
          }

          .project-link {
            position: relative;
            font-size: 14px;
            width: 100%;

            &:hover {
              cursor: pointer;
            }
          }

          .action-btn {
            svg {
              path {
                fill: var(--OffWhite);
                transition: 0.3s ease-in-out;
              }
            }
          }
        }

        .table-data:first {
          width: 60%;
        }
      }
    }
  }

  // Project list grid view

  .project-list-grid {
    padding-bottom: 32px;

    .sort-row {
      padding: 16px 24px;
      border-top: 1px solid var(--mm-colour-surface-400);
      border-bottom: 1px solid var(--mm-colour-surface-400);
      margin-bottom: 32px;
      font-weight: 500;
      background: var(--bgDarkGray);

      .sort-text {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        svg {
          margin-right: 10px;
          color: var(--lightGray);

          &:hover {
            color: var(--green-ui);
          }
        }
      }
    }

    .grid-block {
      display: flex;
      flex-wrap: wrap;
      row-gap: 18px;
      column-gap: 24px;
      // justify-content: space-between;
      padding: 24px;

      .add-project-btn-adapative {
        background-color: var(--purplePrimary);
        padding: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          height: 100%;
          width: 100%;
          color: var(--bgInputBlack);
        }

        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: calc(0.7vw + 9px);
          }

          @media (min-width: 1024px) {
            font-size: calc(0.65vw + 9px);
          }

          @media (min-width: 1536px) {
            font-size: calc(0.7vw + 10px);
          }
        }
      }

      .project-block {
        width: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        border: solid var(--mm-border-size) transparent;
        aspect-ratio: 2.37 / 3;
        transition: 0.3s ease-in-out;

        &:hover {
          border: solid var(--mm-border-size) var(--purplePrimary);
        }

        &.selected {
          border-color: var(--green-ui);
          transition: 0.3s ease-in-out;

          &::after {
            content: "";
            position: absolute;
            background-color: var(--purplePrimary);
            opacity: 0.3;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
          }

          .checkbox-group {
            input[type="checkbox"] {
              &.gray {
                &+label {
                  &::before {
                    border-color: var(--purplePrimary);
                  }
                }
              }
            }
          }

          .table-data {
            .project-detail {
              transition: 0.3s ease-in-out;
              background-color: var(--green-ui);

              a {
                color: var(--bgDarkGray);
              }

              span {
                color: var(--bgDarkGray);
              }
            }
          }
        }

        &.clicked {
          border-color: var(--green-ui);

          .checkbox-group {
            input[type="checkbox"] {
              &.gray {
                &+label {
                  &::before {
                    border-color: var(--green-ui);
                  }
                }
              }
            }
          }

          .table-data {
            .project-detail {
              // background-color: transparent;
              // opacity: 1;
              transition: 0.3s ease-in-out;

              a,
              span {
                color: var(--OffWhite);
                transition: 0.3s ease-in-out;
              }
            }
          }
        }

        .checkbox-group {
          position: absolute;
          top: 8px;
          left: 8px;
          right: 8px;
          z-index: 2;
          height: 16px;
          width: 16px;

          input[type="checkbox"] {
            &+ {
              label {
                cursor: pointer;

                &::before {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }

        .table-data {
          height: 100%;

          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
          }

          .project-detail {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            padding: 10px;
            opacity: 0.8;
            background-color: var(--bgInputBlack);
            display: flex;
            flex-direction: column;

            a {
              color: var(--OffWhite);
              font-size: 19px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              text-transform: capitalize;

              &.project-link {
                position: relative;
              }
            }

            span {
              color: var(--OffWhite);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              transition: 0.3s ease-in-out;
              text-transform: capitalize;
            }
          }
        }

        @media (min-width: 576px) {
          width: calc(50% - 22px);
        }

        @media (min-width: 768px) {
          width: calc(33.33% - 22px);
        }

        @media (min-width: 1024px) {
          width: calc(25% - 22px);
        }

        @media (min-width: 1280px) {
          width: calc(20% - 22px);
        }
      }
    }
  }

  .pagination-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    .pagination-text {
      color: #000000;
      font-size: 15px;
      font-weight: 600;
    }

    .pagination-btn {
      border: none;
      padding: 5px 10px;
      font-size: 20px;
      outline: none;
      border-radius: 5px;
    }
  }

  .no-project-screen {
    min-height: calc(100vh - 253px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
      min-height: calc(100vh - 222px);
    }

    @media (min-width: 1300px) {
      min-height: calc(100vh - 264px);
    }

    .no-project-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      height: 100%;
    }

    .data-heading {
      color: var(--OffWhite);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 991px) {
        line-height: 4;
        margin-bottom: 0;
      }
    }

    .data-content {
      text-align: center;
      font-family: Void;
      font-size: calc(10vw + 10px);
      font-style: normal;
      font-weight: 700;
      line-height: 1;

      @media (min-width: 1024px) {
        font-size: 140px;
      }

      @media (min-width: 1280px) {
        font-size: 175px;
      }
    }

    .action-wrapper {
      margin-top: 30px;

      .clear-filter-link {
        &:hover {
          color: var(--purplePrimary);
        }
      }
    }
  }
}

/*
.name-input-wrapper {
  position: relative;
  .form-input {
    width: 100%;
    height: 36px;
  }

  .cus-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 12px;
    text-transform: capitalize;
    color: var(--bgBlackLite);
    background: var(--purplePrimary);
    padding: 0 5px;
    min-width: auto;
    min-height: 28px;
  }
}

*/

.grid-block {
  .name-input-wrapper {
    width: 100%;
  }
}

.pagination {
  justify-content: right;
  margin-right: 24px;
  margin-top: 20px;

  li {
    border: solid var(--mm-border-size) var(--borderGray);
    margin-right: -1px;
    border-radius: 0;

    .page-link {
      border-radius: 0;
      background-color: transparent;
      color: var(--OffWhite);
      font-size: 15px;
      border: none;
      font-weight: 500;
      min-width: 40px;
      border-radius: 0 !important;
      text-align: center;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus,
      &:hover {
        background-color: var(--green-ui);
        color: #000;
      }
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  .page-item.active {
    background-color: var(--green-ui);
  }
}

/*1024px*/

@media (max-width: 767px) {
  .projects-main {
    .topbar {
      .link-wrapper {
        .button-wrapper {
          margin-top: 0;
        }
      }
    }
  }

  .project-list-container {
    .no-project-screen {
      .data-heading {
        font-size: 20px;
      }
    }
  }
}

/* responsive table views */

.table-compressed, .table-mobile {
  display: none;
}

.nameHeading {
  padding-left: 20px;
}

@media (max-width: 991px) {

  .templateText,
  .statusText,
  .table-hide-compressed {
    display: none !important;
  }

  .table-row {
    grid-template-columns: 0.6fr 0.4fr !important;
  }

  .table-compressed {
    display: inline;
  }
}

@media (max-width: 576px) {
  .table-hide-mobile,
  .left-actions,
  .table-checkbox + label,
  .checkbox-group input[type=checkbox] + label {
    display: none !important;
  }

  .table-row {
    grid-template-columns: 1fr !important;
  }

  .action-row{
    flex-direction: column;
    align-items: flex-start;
  }

  .checkbox-group{
    align-items: flex-end;
    flex-direction: row-reverse;
  }

  .table-mobile {
    display: inline;
  }
}


.table-data {
  text-overflow: ellipsis;
  //overflow: hidden;
  white-space: nowrap;
}

.project-link {

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  display: flex;
  align-items: center;

  .title-text {
    margin-left: 8px;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;

  }

  .status-btn-span{
    margin-right: 20px;
  }

  
}

.in-table{
  margin-left: 8px;
  margin-right: 8px;
}

.rename-wrapper {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 36px;
  }

  button {
    font-size: 13px;
    text-transform: capitalize;
    //color: var(--bgBlackLite);
    //background: var(--purplePrimary);
    padding: 0 5px;
    min-width: auto;
    min-height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
    aspect-ratio: 1;
    border-radius: 100px;
    border: none;
    background-color: var(--mm-colour-secondary-500);
    color: var(--mm-colour-surface-500);
    font-weight: 1000;
  }
}

.templateText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-body{
  padding-bottom: 100px;
}

.table-checkbox {
  display: none;

  &+label {
    position: relative;

    &::before {
      content: "";
      height: 14px;
      width: 14px;
      border: solid var(--mm-border-size) #fff;
      border-radius: 2px;
      display: flex;
    }
  }

  &:checked+label {
    &::before {
      background-image: url("../public/white-checked.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: solid var(--mm-border-size) var(--green-ui);
    }
  }

  &.some-checked+label {
    &::before {
      content: "";
      justify-content: center;
      background: var(--bgDarkGray) url("../public/1x10.jpg") no-repeat center center;
    }
  }
}