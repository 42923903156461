.checkbox-group {
  height: 14px;
  width: 14px;
  display: flex;
  //margin-right: 10px;
  justify-content: space-between;

  input[type="checkbox"] {
    display: none;

    & + label {
      position: relative;

      &::before {
        content: "";
        height: 14px;
        width: 14px;
        border: solid var(--mm-border-size) #fff;
        border-radius: 2px;
        display: flex;
        position: absolute;
      }
    }

    &:checked + label {
      &::before {
        background-image: url("../public/white-checked.svg");
        background-repeat: no-repeat;
        background-position: center;
        border: solid var(--mm-border-size) var(--green-ui);
      }
    }

    &.some-checked + label {
      &::before {
        content: "";
        justify-content: center;
        background: var(--bgDarkGray) url("../public/1x10.jpg") no-repeat center
          center;
      }
    }
  }

  input[type="checkbox"] {
    &.gray + label {
      &::before {
        border: solid var(--mm-border-size) var(--OffWhite);
      }
    }

    &.gray:checked + label {
      &::before {
        background-image: url("../public/checked.svg");
        background-repeat: no-repeat;
        background-position: center;
        border: solid var(--mm-border-size) var(--green-ui);
      }
    }
  }
}

.checkName {
  display: flex;
  cursor: pointer;
}

.delete-modal-container {
  text-align: center;

  .custom-modal-heading {
    font-size: 40px;
    text-transform: uppercase;
  }

  .custom-modal-text {
    padding: 10px 0px 30px 0px;
  }

  .modal-button-container {
    button {
      height: auto;
      font-size: 13px;
    }
  }
}

@media (max-width: 767px) {
  .delete-modal-container {
    .custom-modal-heading {
      font-size: 25px;
    }

    .modal-button-container {
      button {
        height: 40px;
      }
    }
  }
}

.item-grid-boxes {
  width: auto !important;
  justify-content: space-between;
}

.status-btn {
  padding: 9px 18px;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  border: none;
  color: var(--bgDarkGray);

  &:hover {
    opacity: 0.8;
  }
}

.status-btn-span{
  line-height: 0px;
}

.action-btn {
  padding: 6px 16px;
  border: solid var(--mm-border-size) var(--OffWhite);
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: var(--bgDarkGray);
  color: var(--OffWhite);

  &:not(:disabled):hover {
    border: solid var(--mm-border-size) var(--purplePrimary);
    color: var(--purplePrimary);

    svg {
      path {
        fill: var(--purplePrimary) !important;
      }
    }
  }

  &.readonly {
    // border: solid var(--mm-border-size) var(--mm-colour-surface-400);
  }

  &:disabled {
    opacity: 0.3 !important;
  }

  svg {
    margin-right: 8px;
    path {
      fill: var(--OffWhite);
      transition: 0.3s ease-in-out;
    }
  }
}

.status-circle {
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-brand-black, #000);
  width: 12px;
  height: 12px;
  border-radius: 50px;
  padding: 0;
}

