.error-page {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    .error-screen {
        height: calc(100% - 190px);

        .error-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            height: 100%;

            @media (min-width: 768px) {
                padding-left: 50px;
            padding-right: 50px;
            }
        }

        .data-heading {
            color: var(--OffWhite);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 20px;

            @media (min-width: 991px) {
                line-height: 4;
                margin-bottom: 0;
            }
        }

        .data-content {
            text-align: center;
            font-family: Void;
            font-size: calc(10vw + 10px);
            font-style: normal;
            font-weight: 700;
            line-height: 1;

            @media (min-width: 1024px) {
                font-size: 140px;
            }

            @media (min-width: 1280px) {
                font-size: 175px;
            }
        }

        .action-wrapper {
            margin-top: 50px;
        }
    }
}