.reset-password-wrap {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height : 100vh;
    color : var(--OffWhite);

    .reset-password-content {
        background-color: var(--bgBlackLite);
        border: solid var(--mm-border-size) var(--OffWhite);
        padding : 30px;
        max-width: 500px;
        border-radius: 0.5rem;

        .loginInner {
            width: 100%;
            background: var(--bgBlackLite);
            position: relative;
            z-index: 1;

            h3 {
                padding: 0;
                color: var(--OffWhite);
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 56px;
                text-transform: uppercase;
                margin-top: 30px;
            }

            p {
                font-size: 16px;
                text-align: center;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: var(--OffWhite);
                margin-top: 20px;
            }
        }

        .controlWrap {
            padding : 0;

            .control {
                margin-top : 24px;

                label {
                    color: #F2F5F8;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }

                input {
                    border-radius: 5px;
                    border: solid var(--mm-border-size) #7F8281;
                    background: var(--bgInputBlack);
                    color: var(--grayD7D7);
                    height: 55px;
                    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
                    width: 100%;
                    padding: 0 18px;
                    font-size: 13px;
                    outline: none;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                    &::placeholder{
                        color: var(--mm-colour-surface-400);
                        font-size: 13px;
                        font-weight: 500;
                    }
                }

                input:focus {
                    border: solid var(--mm-border-size) var(--green-ui);
                }

                button {
                    width: 100%;
                }

                .forgot-pass-label{
                    display: block;
                    @include mm-body-font;
                    color: var(--OffWhite);
                    text-align: center;
                    margin-top: 18px;
                    text-decoration: underline;
                    text-transform: capitalize;
                }

                a {
                    cursor : pointer;
                }
            }
        }
    }
}