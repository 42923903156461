:root {
    --green-ui: #7371FF;
    --purplePrimary: #7371FF;
    --green-ui-hover: #7371FF;
    --green: #39ff6c;
    --lightGreen: #d8ffe2;
    --red-ui: rgba(238, 14, 14, 0.9);
    --red-ui-hover: rgba(238, 14, 14, 0.4);
    --nav-background: rgb(212, 210, 227);
    --btn-border: rgb(52, 52, 52);
    --text-color: rgb(93, 90, 136);
    --text-color-light: rgb(151, 149, 181);
    --bgDarkGray: #070707;
    --lightGray: #c4c5c6;
    --grayD7D7: #d7d7d7;
    --bgBlackLite: #070707;
    --bgInputBlack: #181819;
    --whitColor: #fff;
    --OffWhite: #f2f5f8;
    --borderGray: #7f8281;
    --lightWhite: #e9f3ee;
    --lightPurpleBg: #dcdbff;
    --lightCream: #d8ffe2;
    --redText: #f45848;
    --greenText: #39ff6c;
    --status-btn-hover: #7371FF;
    --status-btn-hover-text: #070707;
    --lightPurple: #b3c1ff;
    --neonGreen: #9cffb1;
    --gray333:#3E4046;

    /* EXPORTED LIBARY */ 
    --mm-colour-surface-400: #3E4046;
    --mm-editor-background: #E2E2E2;
    --mm-colour-surface-500: #28282F;
    --mm-colour-surface-100: #EDF0F4;
    --mm-colour-surface-300: #B5B8BB;

    --mm-colour-secondary-500: #95FFAC;
    --mm-colour-secondary-300: #DDFFE4;

    /* OTHER SETTINGS */
    --mm-border-size: 1px;
    
  }
  
  @mixin effects-larger-shadow{
    filter: drop-shadow(0px 20px 32px #1A1A1A);
  }
  
  @mixin effects-regular-shadow{
    filter: drop-shadow(0px 18px 13px rgba(0, 0, 0, 0.25));
  }
  
  @mixin effects-small-shadow{
    filter: drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.12));
  }

